.step3 {
    &__block_verify {
        margin: 186px 60px 0;
        &__up_block {
            background: #fff;
            text-align: center;
            padding: 16px 16px 11px 16px;
            border-radius: 14px 14px 0 0;
            h2 {
                font-weight: 590;
                font-size: 17px;
                line-height: 22px;
            }
            p {
                font-size: 13px;
                line-height: 18px;
            }
            &__text_first {
                
            }
            &__text_second {
                margin-top: 20px;
            }
        }
        button {
            width: 100%;
            margin-top: 1px;
            font-size: 17px;
            color: #007AFF;
            font-weight: 590;
            line-height: 22px;
            padding: 11px 16px;
            background: #fff;
            border-radius: 0 0 14px 14px;
        }
    }
}