.step3 {
    h2 {
        padding: 20px 0 0 20px;
        font-size: 29px;
        font-weight: 700;
        line-height: 35.1px;
    }
    &__content {
        padding: 0px 16px;
        input {
            width: 100%;
            height: 48px;
            color: #A2ACB0;
            font-size: 17px;
            margin-top: 34px;
            padding: 13px 16px;
            background: #fff;
            border-radius: 12px;
        }
        .error_input {
            border: 2px solid red;
        }
        &__qr_code {
            margin-top: 16px;
            img {
                width: 100%;
                border-radius: 16px;
            }
        }
        &__copy {
            gap: 15px;
            width: 100%;
            height: 80px;
            padding: 8px;
            display: flex;
            margin-top: 15px;
            border-radius: 8px;
            align-items: center;
            background: #cfcece;
            justify-content: space-around;
            p {
                word-break: break-word;
            }
        }
    }
    &__btn {
        margin-top: 66px;
        text-align: center;
        button {
            width: 358px;
            height: 52px;
            color: #fff;
            font-size: 17px;
            font-weight: 590;
            line-height: 22px;
            text-align: center;
            border-radius: 12px;
            background: #007AFF;
        }
        .disabled {
            background: rgba(0, 0, 0, 0.1490196078);
        }
    }
}