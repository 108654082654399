.wallet {
    height: 100vh;
    padding: 20px;
    text-align: center;
    .wallet__header {
        h2 {
            font-size: 17px;
            font-weight: 590;
            line-height: 22px;
        }
        &__total_price {
            font-size: 46px;
            margin-top: 8px;
            font-weight: 700;
            line-height: 45.35px;

            span {
                font-size: 38px;
                color: #707579;
                font-weight: 700;
                line-height: 45.35px;
            }
        }
        button {
            width: 100%;
            height: 55px;
            color: #fff;
            font-size: 17px;
            font-weight: 700;
            margin-top: 32px;
            border-radius: 12.38px;
            background-color: #007AFF;
        }
    }
    .wallet__currencies {
        display: grid;
        grid-gap: 8px;
        margin-top: 18px;
        border-radius: 11px;
        .wallet__currencies__currency {
            display: flex;
            padding: 12px 15px;
            background: #fff;
            align-items: center;
            border-radius: 11px;
            justify-content: space-between;
            .wallet__currencies__currency__image {
                gap: 16px;
                display: flex;
                align-items: center;
                img {
                    width: 40px;
                    height: 40px;
                }
                p {
                    font-size: 17px;
                    font-weight: 510;
                    line-height: 22px;
                }
            }
            .wallet__currencies__currency__value {
                gap: 2px;
                display: flex;
                flex-direction: column;
                p {
                    font-size: 15px;
                    text-align: right;
                    line-height: 20.57px;
                    &:last-child {
                        color: #707579;
                    }
                }
            }
        }
    }
}