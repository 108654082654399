.choose_active {
    h2 {
        padding: 20px 0 0 20px;
        font-size: 29px;
        font-weight: 700;
        line-height: 35.1px;
    }
    h3 {
        margin-top: 32px;
        padding-left: 20px;
        font-weight: 590;
        font-size: 16px;
        line-height: 21px;
    }
    &__coins {
        margin-top: 12px;
        background: #fff;
        &_coin {
            padding: 0px 24px 12px 16px;
            &:first-child {
                padding: 14px 24px 14px 16px;
            }
            &:last-child {
                padding-bottom: 0;
            }
            gap: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            &__content {
                gap: 16px;
                display: flex;
                align-items: center;
                padding-bottom: 0px;
                &:last-child {
                    padding-bottom: 0px;
                }
                img {
                    width: 40px;
                    height: 40px;
                }
            }
            &__action {
                width: 100%;
                display: flex;
                align-items: center;
                padding-bottom: 12px;
                justify-content: space-between;
                border-bottom: 1px solid #00000026;
                &__text {
                    p {
                        &:first-child {
                            color: #000000;
                            font-size: 17px;
                            line-height: 22px;
                            font-weight: 510;
                        }
                        &:last-child {
                            color: #707579;
                            font-size: 15px;
                            line-height: 20px;
                        }
                    }
                }
            }
            &__last {
                border-bottom: none;
            }
        }
    }
    &__description {
        width: 342px;
        text-align: left;
        margin: 16px auto auto;
        p {
            font-size: 13px;
            font-weight: 400;
            color: #707579;
            line-height: 16px;
        }
    }
    &__btn {
        margin-top: 24px;
        text-align: center;
        button {
            width: 358px;
            height: 52px;
            color: #fff;
            font-size: 17px;
            font-weight: 590;
            line-height: 22px;
            text-align: center;
            border-radius: 12px;
            background: #007AFF;
        }
    }
}
