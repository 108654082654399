.welcome {
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    justify-content: space-evenly;

    .welcome__img_block {
        width: 350px;
        height: 350px;
        margin: 0 auto;
        overflow: hidden;
        border-radius: 50%;
        border: 30px solid #dcf8d6;
    }
    .welcome__title {
        text-align: center;
        h1 {
            color: #000;
            font-family: MontserratAlternates, sans-serif;
            font-size: 30px;
            line-height: 38.4px;
            margin-bottom: 20px;
        }
        p {
            font-size: 12px;
            color: #000;
            line-height: 14.4px;
            margin-bottom: 60px;
        }
    }
}