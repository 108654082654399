.wallet__content {
    gap: 30px;
    display: flex;
    margin-top: 16px;
    align-items: center;
    justify-content: center;
    .wallet__content__send, .wallet__content__topup, .wallet__content__exchange {
        gap: 8px;
        display: flex;
        align-items: center;
        flex-direction: column;
        svg {
            font-size: 25px;
            color: #007AFF;
        }
        p {
            color: #007AFF;
            font-weight: 550;
        }
    }
}