.transaction_info {
    padding: 20px;
    h2 {
        font-size: 17px;
        font-weight: 590;
        line-height: 22px;
    }
    .transaction_info__crypto_sum {
        margin-top: 5px;
        p {
            font-size: 56px;
            color: #31D158;
            font-weight: 700;
            line-height: 66.83px;
        }
    }
    .transaction_info__rubble_sum {
        p {
            font-size: 20px;
            color: #31D158;
            font-weight: 700;
            line-height: 24px;
        }
    }
    .transaction_info__date {
        margin-top: 5px;
        p {
            font-size: 17px;
            color: #707579;
            line-height: 22px;
        }
    }
    .transaction_info__status {
        gap: 5px;
        display: flex;
        margin-top: 16px;
        background: #fff;
        padding: 12px 16px;
        border-radius: 16px;
        flex-direction: column;
        .transaction_info__status_header {
            color: #707579;
            font-size: 15px;
            font-weight: 400;
            line-height: 20px;
        }
        .transaction_info__status_cnt {
            color: #000;
            font-size: 17px;
            font-weight: 400;
            line-height: 22px;
        }
    }
}