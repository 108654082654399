.currency_info {
    margin-top: 24px;
    .currency_info__header {
        text-align: center;
        img {
            width: 64px;
            height: 64px;
        }
        .currency_info__name {
            margin-top: 20px;
            p {
                font-size: 17px;
                font-weight: 510;
                line-height: 22px;
            }
        }
        .currency_info__amount {
            margin-top: 4px;
            p {
                font-size: 32px;
                font-weight: 700;
                line-height: 38px;
            }
        }
        .currency_info__amount_coin {
            margin-top: 4px;
            p {
                font-size: 17px;
                color: #707579;
                font-weight: 400;
                line-height: 22px;
            }
        }
    }
}