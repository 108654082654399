:where(.css-dev-only-do-not-override-ccdg5a).ant-slider .ant-slider-track {
    border-radius: 2px;
    background-color: #007AFF;
}

:where(.css-dev-only-do-not-override-ccdg5a).ant-slider .ant-slider-rail {
    background-color: #fff;
}

.ant-tooltip {
    display: none;
}