.step2_verification {
    h2 {
        padding-top: 20px;
        font-size: 29px;
        font-weight: 700;
        line-height: 35.1px;
        text-align: center;
    }
    &__info {
        margin-top: 16px;
        text-align: center;
        p {
            font-size: 16px;
            line-height: 21px;
            align-items: center;
            &:last-child {
                font-weight: 700;
            }
        }
    }
    &__code {
        margin: 40px 0px 32px 0px;
        text-align: center;
    }
    &__info_repeat {
        text-align: center;
        p {
            font-size: 14px;
            color: #1A1A1A;
            font-weight: 500;
            line-height: 20px;
            align-items: center;
        }
    }
    &__btn {
        margin-top: 16px;
        padding: 0px 16px;
        button {
            width: 100%;
            height: 52px;
            font-size: 17px;
            color: #FFFFFF;
            font-weight: 590;
            line-height: 22px;
            align-items: center;
            border-radius: 12px;
            background: #007AFF;
        }
    }
}