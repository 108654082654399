.card {
    padding: 20px;
    h2 {
        font-size: 29px;
        font-weight: 700;
        line-height: 35.1px;
    }
    .card__tokens {
        .card__tokens__sub_title {
            font-size: 13px;
            color: #707579;
            margin-top: 32px;
            font-weight: 400;
            line-height: 16px;
            text-transform: uppercase;
        }
        .card__tokens__flex {
            display: flex;
            margin-top: 10px;
            justify-content: space-between;
            .card__tokens__token {
                gap: 13px;
                width: 108px;
                opacity: 55%;
                height: 111px;
                display: flex;
                padding: 12px;
                text-align: center;
                background: #fff;
                border-radius: 9.49px;
                flex-direction: column;
                box-shadow: 1.72px 1.72px 12.94px 0px #00000014;
                .card__tokens__token__header {
                    gap: 5px;
                    display: flex;
                    align-items: center;
                    img {
                        width: 32px;
                        height: 32px;
                    }
                    p {
                        font-weight: bold;
                    }
                }
                .card__tokens__token__footer {
                    gap: 5px;
                    display: flex;
                    flex-direction: column;
                    p {
                        &:first-child {}
                        &:last-child {
                            color: #707579;
                            font-size: 12px;
                        }
                    }
                }
            }
            .active {
                opacity: 100%;
            }
        }
    }
    .card__loan_amount {
        margin-top: 40px;
        h3 {
            font-size: 13px;
            color: #969696;
            line-height: 16px;
            text-transform: uppercase;
        }
        .card__loan_amount__input {
            input {
                width: 100%;
                height: 55px;
                color: #000;
                font-size: 17px;
                margin-top: 15px;
                padding: 13.75px;
                font-weight: 700;
                line-height: 20.57px;
                border-radius: 12.38px;
                border: 1px solid #007AFF;
            }
        }
    }
    .card__unrequired_collateral {
        margin-top: 25px;
        .card__unrequired_collateral__value {
            p {
                font-size: 13px;
                color: #707579;
                line-height: 16px;

                span {
                    color: #000;
                    font-size: 13px;
                    font-weight: 700;
                    line-height: 16px;
                }
            }
        }
    }
    .card__accept_btn {
        button {
            width: 100%;
            height: 55px;
            color: #fff;
            font-size: 17px;
            font-weight: 700;
            margin-top: 30px;
            border-radius: 12.38px;
            background-color: #007AFF;
        }
        :disabled {
            background-color: #C2C2C2;
        }
    }
}