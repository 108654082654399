* {
    padding: 0;
    margin: 0;
    border: 0;
  }
  
  *,
  *:before,
  *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  :focus,
  :active {
    outline: none;
  }
  
  a:focus,
  a:active {
    outline: none;
  }
  
  nav,
  footer,
  header,
  aside {
    display: block;
  }
  
  html,
  body {
    height: 100%;
    width: 100%;
    font-size: 100%;
    line-height: 1;
    font-size: 14px;
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }
  
  html {
    scrollbar-width: auto;
    scrollbar-color: #777 #555;
  }
  
  html::-webkit-scrollbar {
    width: 10px;
  }
  
  html::-webkit-scrollbar-thumb {
    background-color: #7775;
  }
  
  html::-webkit-scrollbar-thumb:hover {
    background-color: #777;
  }
  
  html::-webkit-scrollbar-track {
    background-color: #5555;
  }
  
  html::-webkit-scrollbar-track:hover {
    background-color: #555;
  }
  
  input,
  button,
  textarea {
    font-family: inherit;
  }
  
  input::-ms-clear {
    display: none;
  }
  
  button {
    cursor: pointer;
  }
  
  button::-moz-focus-inner {
    padding: 0;
    border: 0;
  }
  
  a,
  a:visited {
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: none;
  }
  
  ul li {
    list-style: none;
  }
  
  img {
    vertical-align: top;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: inherit;
    font-weight: 400;
  }

  // Подключение Шрифта

  @font-face {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 300;
    src: url("https://raw.githubusercontent.com/blaisck/sfwin/master/SFPro/TrueType/SFProText-Light.ttf");
  }
  @font-face {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 400;
    src: url("https://raw.githubusercontent.com/blaisck/sfwin/master/SFPro/TrueType/SFProText-Regular.ttf");
  }
  @font-face {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 500;
    src: url("https://raw.githubusercontent.com/blaisck/sfwin/master/SFPro/TrueType/SFProText-Medium.ttf");
  }
  @font-face {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 600;
    src: url("https://raw.githubusercontent.com/blaisck/sfwin/master/SFPro/TrueType/SFProText-SemiBold.ttf");
  }
  @font-face {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 700;
    src: url("https://raw.githubusercontent.com/blaisck/sfwin/master/SFPro/TrueType/SFProText-Bold.ttf");
  }
  @font-face {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 900;
    src: url("https://raw.githubusercontent.com/blaisck/sfwin/master/SFPro/TrueType/SFProText-Heavy.ttf");
  }
  @font-face {
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 100;
    src: url("https://raw.githubusercontent.com/blaisck/sfwin/master/SFPro/TrueType/SFProDisplay-Ultralight.ttf");
  }
  @font-face {
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 200;
    src: url("https://raw.githubusercontent.com/blaisck/sfwin/master/SFPro/TrueType/SFProDisplay-Thin.ttf");
  }
  @font-face {
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 300;
    src: url("https://raw.githubusercontent.com/blaisck/sfwin/master/SFPro/TrueType/SFProDisplay-Light.ttf");
  }
  @font-face {
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 400;
    src: url("https://raw.githubusercontent.com/blaisck/sfwin/master/SFPro/TrueType/SFProDisplay-Regular.ttf");
  }
  @font-face {
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    src: url("https://raw.githubusercontent.com/blaisck/sfwin/master/SFPro/TrueType/SFProDisplay-Medium.ttf");
  }
  @font-face {
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 600;
    src: url("https://raw.githubusercontent.com/blaisck/sfwin/master/SFPro/TrueType/SFProDisplay-SemiBold.ttf");
  }
  @font-face {
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 700;
    src: url("https://raw.githubusercontent.com/blaisck/sfwin/master/SFPro/TrueType/SFProDisplay-Bold.ttf");
  }
  @font-face {
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 800;
    src: url("https://raw.githubusercontent.com/blaisck/sfwin/master/SFPro/TrueType/SFProDisplay-Black.ttf");
  }
  @font-face {
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 900;
    src: url("https://raw.githubusercontent.com/blaisck/sfwin/master/SFPro/TrueType/SFProDisplay-Heavy.ttf");
  }

  body {
    color: #000;
    background: #fff;
    font-family: 'SF Pro Display', sans-serif;
  }