.profile {
    h2 {
        padding: 20px;
        font-size: 29px;
        font-weight: 700;
        line-height: 35.1px;
    }
    .profile__linked_cards {
        background: #fff;
        .profile__linked_cards__header {
            display: flex;
            padding: 20px 16px 0px 16px;
            justify-content: space-between;
            h3 {
                font-size: 13px;
                color: #707579;
                font-weight: 400;
                line-height: 16px;
                text-transform: uppercase;
            }
            button {
                font-size: 15px;
                padding: 2px 8px;
                color: #007AFF;
                font-weight: 590;
                line-height: 20px;
                border-radius: 20px;
                background: #4378FF1A;
            }
        }
        .profile__linked_cards__cards {
            margin-top: 36px;

            .profile__linked_cards__card {
                display: flex;
                padding: 23px 16px;
                align-items: center;
                justify-content: space-between;
                border-top: 1px solid #e5e4e4;
                .left_block {
                    display: flex;
                    align-items: center;
                }
                .left_block {
                    gap: 16px;
                }
                .right_block {
                    gap: 8px;
                    button {
                        gap: 8px;
                        display: flex;
                        align-items: center;
                        font-size: 15px;
                        color: #707579;
                        font-weight: 510;
                        background: none;
                        line-height: 20px;
                    }
                }
            }
        }
    }
    .profile__current_loan {
        margin-top: 18px;
        background: #fff;
        .profile__current_loan__header {
            display: flex;
            padding: 20px 16px 0px 16px;
            justify-content: space-between;
            h3 {
                font-size: 13px;
                color: #707579;
                font-weight: 400;
                line-height: 16px;
                text-transform: uppercase;
            }
        }
        .profile__current_loan__loans {
            // margin-top: 18px;
            .profile__current_loan__loan {
                padding: 16px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid #e5e4e4;
                .left_block {
                    gap: 16px;
                    display: flex;
                    align-items: center;
                    img {
                        width: 48px;
                        height: 48px;
                    }
                    p {
                        font-size: 17px;
                        font-weight: 510;
                        line-height: 22px;
                    }
                }
                .right_block {
                    text-align: right;
                    p {
                        &:first-child {
                            font-size: 17px;
                            font-weight: 510;
                            line-height: 22px;
                        }
                        &:last-child {
                            font-size: 15px;
                            font-weight: 400;
                            line-height: 20px;
                        }
                    }
                }
            }
        }
    }
}