.timer {
    padding: 0 16px;
    &__btn {
        color: #007AFF;
    }
    // .btnAction {
    //     margin-top: 16px;
    //     width: 100%;
    //     height: 52px;
    //     font-size: 17px;
    //     color: #FFFFFF;
    //     font-weight: 590;
    //     line-height: 22px;
    //     align-items: center;
    //     border-radius: 12px;
    //     background: #007AFF;
    // }
    // .btn_disabled {
    //     opacity: 0.35;
    // }
}