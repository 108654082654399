.navbar {
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 15px;
    display: flex;
    position: fixed;
    background: #00000026;
    justify-content: space-around;
    .navbar__img_block {
        gap: 9px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        p {
            color: #707579;
            font-size: 11px;
            font-weight: 700;
            line-height: 13.31px;
        }
    }
    .active {
        p {
            color: #007AFF;
        }
    }
}