.add_money {
    h2 {
        font-size: 29px;
        font-weight: 700;
        line-height: 35.1px;
        padding: 20px 20px 0px 20px;
    }
    &__actions {
        margin-top: 30px;
        margin-bottom: 14px;
        padding-left: 20px;
        h3 {
            font-size: 16px;
            font-weight: 590;
            line-height: 21px   ;
        }
    }
    &__choosed {
        &__card, &__external_wallet {
            gap: 16px;
            display: flex;
            background: #fff;
            align-items: center;
            padding: 12px 0px 12px 16px;
            border-bottom: 0.5px solid #00000026;
            &:last-child {
                margin-top: 0;
                border-bottom: none;
            }
            &__bank_card {
                gap: 2px;
                display: flex;
                flex-direction: column;
                p {
                    &:first-child {
                        font-size: 17px;
                        font-weight: 510;
                        line-height: 22px;
                    }
                    &:last-child {
                        font-size: 15px;
                        color: #707579;
                        font-weight: 400;
                        line-height: 20px;
                    }
                }
            }
        }
    }
    
}