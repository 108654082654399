.step4 {
    h2 {
        padding: 20px 0 0 20px;
        font-size: 29px;
        font-weight: 700;
        line-height: 35.1px;
    }
    &__content {
        position: relative;
        margin-top: 34px;
        padding: 0px 16px;
        input {
            width: 100%;
            height: 48px;
            color: #A2ACB0;
            font-size: 17px;
            background: #fff;
            border-radius: 12px;
            padding: 13px 60px 13px 16px;
        }
        button {
            top: 50%;
            right: 30px;
            color: #007AFF;
            background: none;
            position: absolute;
            transform: translate(0, -50%);
        }
    }
    &__btn {
        margin-top: 66px;
        text-align: center;
        button {
            width: 358px;
            height: 52px;
            color: #fff;
            font-size: 17px;
            font-weight: 590;
            line-height: 22px;
            text-align: center;
            border-radius: 12px;
            background: #007AFF;
        }
    }
}