.currency_info__transactions {
    padding: 16px;
    margin-top: 14px;
    background: #fff;
    h2 {
        font-size: 13px;
        color: #707579;
        font-weight: 400;
        line-height: 16px;
        text-transform: uppercase;
    }
    .currency_info__transactions__items {
        display: grid;
        grid-gap: 24px;
        margin-top: 20px;
        grid-template-rows: auto;
        .transaction_add {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .transaction_add__left {
                gap: 2px;
                display: flex;
                flex-direction: column;
                .transaction_title {
                    font-size: 17px;
                    font-weight: 510;
                    line-height: 22px;
                }
                .transaction_date {
                    font-size: 15px;
                    font-weight: 400;
                    color: #707579;
                    line-height: 20px;
                }
            }
            .transaction_right {
                gap: 2px;
                display: flex;
                flex-direction: column;
                .transaction_add_coint {
                    font-size: 17px;
                    font-weight: 400;
                    color: #31D158;
                    line-height: 22px;
                }
                .transaction_add_rub {
                    font-size: 15px;
                    color: #31D158;
                    font-weight: 400;
                    line-height: 20px;
                }
            }
        }
        .transaction_transfer {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .transaction_add__left {
                gap: 2px;
                display: flex;
                flex-direction: column;
                .transaction_title {
                    font-size: 17px;
                    font-weight: 510;
                    line-height: 22px;
                }
                .transaction_date {
                    font-size: 15px;
                    font-weight: 400;
                    color: #707579;
                    line-height: 20px;
                }
            }
            .transaction_right {
                gap: 2px;
                display: flex;
                flex-direction: column;
                .transaction_add_coint {
                    font-size: 17px;
                    font-weight: 400;
                    color: #000000;
                    line-height: 22px;
                }
                .transaction_add_rub {
                    font-size: 15px;
                    color: #707579;
                    font-weight: 400;
                    line-height: 20px;
                }
            }
        }
    }
}