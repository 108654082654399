.step1_verification {
    h2 {
        padding: 20px 0 0 20px;
        font-size: 29px;
        font-weight: 700;
        line-height: 35.1px;
    }
    &__choose {
        &__subtitle {
            font-size: 13px;
            color: #707579;
            line-height: 16px;
            margin: 32px 0px 0px 32px;
            text-transform: uppercase;
        }
        &__icons {
            gap: 8px;
            display: flex;
            margin-top: 8px;
            align-items: center;
            justify-content: center;
            &__icon {
                gap: 10px;
                width: 175px;
                height: 56px;
                display: flex;
                background: #fff;
                align-items: center;
                border-radius: 16px;
                justify-content: center;
                img {
                    width: 32px;
                    height: 32px;
                }
                p {
                    font-size: 17px;
                    font-weight: 510;
                }
            }
        }
    }
    &__block_fields {
        margin-top: 32px;
        &__subtitle {
            font-size: 13px;
            color: #707579;
            line-height: 16px;
            margin: 32px 0px 0px 32px;
            text-transform: uppercase;
        }
        &__form {
            display: grid;
            grid-gap: 8px;
            margin-top: 8px;
            padding: 0px 16px;
            input {
                width: 100%;
                height: 48px;
                font-size: 17px;
                color: #A2ACB0;
                line-height: 22px;
                padding: 15px 16px;
                border-radius: 12px;
            }
        }
        &__btn {
            padding: 0px 16px;
            button {
                width: 100%;
                height: 52px;
                font-size: 17px;
                color: #FFFFFF;
                font-weight: 590;
                margin-top: 31px;
                line-height: 22px;
                align-items: center;
                border-radius: 12px;
                background: #007AFF;
            }
        }
    }
}

@media (max-height: 595px) {
    .step1_verification {
        &__choose {
            &__subtitle {
                margin: 15px 0px 0px 32px;
            }
            &__icons {
                &__icon {
                    height: 40px;
                }
            }
        }
        &__block_fields {
            margin-top: 10px;
            &__subtitle {
                margin: 0 0px 0px 32px;
            }
            &__icons {
                margin-top: 5px;
            }
            &__btn {
                button {
                    margin-top: 20px;
                }
            }
        }
    }
}